<template>
	<modal class="NoxModalServers" name="NoxModalServers" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen" @before-close="beforeClose">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'buyServer' && noxStep == 1" v-html="$store.getters.getLanguageText('5.15', 0)"></span>
					<span v-else-if="noxType == 'buyServer' && noxStep > 1" v-html="$store.getters.getLanguageText('5.15', 2)"></span>
					<span v-else-if="noxType == 'editServerComment'" v-html="$store.getters.getLanguageText('5.15', 8)"></span>
					<span v-else-if="noxType == 'editServerPassword'" v-html="$store.getters.getLanguageText('5.15', 12)"></span>
					<span v-else-if="noxType == 'upgradeServer'" v-html="$store.getters.getLanguageText('5.15', 20)"></span>
					<span v-else-if="noxType == 'refreshServer'" v-html="$store.getters.getLanguageText('5.15', 14)"></span>
					<span v-else-if="noxType == 'extendServerV1'" v-html="$store.getters.getLanguageText('5.15', 22)"></span>
					<span v-else-if="noxType == 'extendServerV2'" v-html="$store.getters.getLanguageText('5.15', 11)"></span>
					<span v-else-if="noxType == 'recreateServer'" v-html="$store.getters.getLanguageText('5.15', 24)"></span>
					<span v-else-if="noxType == 'reinstallServer'" v-html="$store.getters.getLanguageText('5.15', 24)"></span>
					<span v-else-if="noxType == 'restartServer'" v-html="$store.getters.getLanguageText('5.15', 26)"></span>
					<span v-else-if="noxType == 'deleteServer'" v-html="$store.getters.getLanguageText('5.15', 6)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['buyServer', 'extendServerV2'].includes(noxType)">
						<div v-if="noxStep == 1" v-html="$store.getters.getLanguageText('5.15', 1)"></div>
						<div v-else-if="noxStep == 2 || (noxStep == 3 && noxType == 'buyServer' && noxVersion == 2)">
							<div :class="{ hide: noxStep == 3 }">
								<p><span v-html="$store.getters.getLanguageText('5.16', 7, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance1, 2) })"></span><br><span v-html="$store.getters.getLanguageText('5.16', 8, { balance: $parent.$parent.$parent.getNumberFormatForDollar(noxBalance3, 2) })"></span></p>
								<p v-html="$store.getters.getLanguageText('5.15', 3)"></p>
								<p class="nox_p_radios">
									<label class="nox_radio" :for="'nox_radio_server_period_' + index" v-for="(value, index) in noxServerPeriods" :key="index">
										<input type="radio" :id="'nox_radio_server_period_' + index" name="nox_radio_server_period" :value="value" v-model.number="noxServerPeriod">
										<span class="nox_radio_mark"></span>
										<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.15', 4, { options: [(value > 3 ? 2 : (value > 1 ? 1 : 0))], months: value, cost: parseFloat(value == 1 ? noxServerPeriodM1TotalCost : (value == 2 ? noxServerPeriodM2TotalCost : (value == 3 ? noxServerPeriodM3TotalCost : (value == 6 ? noxServerPeriodM6TotalCost : noxServerPeriodM12TotalCost)))).toFixed(2) })"></span></span>
									</label>
								</p>
								<p v-if="((noxServerPeriod == 1 && !noxIsBuyM1) || (noxServerPeriod == 2 && !noxIsBuyM2) || (noxServerPeriod == 3 && !noxIsBuyM3) || (noxServerPeriod == 6 && !noxIsBuyM6) || (noxServerPeriod == 12 && !noxIsBuyM12))"><span class="red" v-html="$store.getters.getLanguageText('5.16', (noxType == 'buyServer' ? 12 : 13), { amount: parseFloat((noxServerPeriod == 1 ? noxServerPeriodM1TotalCost : (noxServerPeriod == 2 ? noxServerPeriodM2TotalCost : (noxServerPeriod == 3 ? noxServerPeriodM3TotalCost : (noxServerPeriod == 6 ? noxServerPeriodM6TotalCost : noxServerPeriodM12TotalCost)))) - noxBalance).toFixed(2) })"></span></p>
							</div>
							<div :class="{ hide: noxStep == 2 }">
								<p v-html="$store.getters.getLanguageText('5.15', 18)"></p>
								<p class="nox_p_radios">
									<label class="nox_radio" :class="{ disabled: !isServerMethodAvailable(value) }" :for="'nox_radio_server_method_' + index" v-for="(value, index) in noxServerMethods" :key="index">
										<input type="radio" :id="'nox_radio_server_method_' + index" name="nox_radio_server_method" :value="value" v-model.number="noxServerMethod" :disabled="!isServerMethodAvailable(value)">
										<span class="nox_radio_mark"></span>
										<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.15', 19, { options: [(value - 1)] })"></span></span>
									</label>
								</p>
							</div>
						</div>
						<div v-else-if="noxStep >= 3" v-html="$store.getters.getLanguageText('5.15', 5, { options: [(noxType == 'extendServerV2' ? 1 : 0)], amount: parseFloat(noxServerPeriod == 1 ? noxServerPeriodM1TotalCost : (noxServerPeriod == 2 ? noxServerPeriodM2TotalCost : (noxServerPeriod == 3 ? noxServerPeriodM3TotalCost : (noxServerPeriod == 6 ? noxServerPeriodM6TotalCost : noxServerPeriodM12TotalCost)))).toFixed(2) })"></div>
					</div>
					<div v-else-if="noxType == 'editServerComment'">
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.15', 9)"></div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxServerComment" :placeholder="$store.getters.getLanguageText('5.15', 10)"></textarea>
								<div v-html="noxAlertServerComment"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'editServerPassword'" v-html="$store.getters.getLanguageText('5.15', 13)"></div>
					<div v-else-if="noxType == 'upgradeServer'" v-html="$store.getters.getLanguageText('5.15', 21)"></div>
					<div v-else-if="noxType == 'refreshServer'">
						<div v-if="noxStep == 1" v-html="$store.getters.getLanguageText('5.15', 15)"></div>
						<div v-else-if="noxStep == 2" v-html="$store.getters.getLanguageText('5.15', 16)"></div>
						<div v-else-if="noxStep == 3" v-html="$store.getters.getLanguageText('5.15', 17, { address: ($parent.noxServersData[noxIndex].version == 2 && $parent.noxServersData[noxIndex].server_hostname ? $parent.noxServersData[noxIndex].server_hostname : $parent.noxServersData[noxIndex].server_ip) + ':' + $parent.noxServersData[noxIndex].server_port })"></div>
					</div>
					<div v-else-if="noxType == 'extendServerV1'" v-html="$store.getters.getLanguageText('5.15', 23)"></div>
					<div v-else-if="noxType == 'recreateServer'" v-html="$store.getters.getLanguageText('5.15', 25)"></div>
					<div v-else-if="noxType == 'reinstallServer'" v-html="$store.getters.getLanguageText('5.15', 25)"></div>
					<div v-else-if="noxType == 'restartServer'" v-html="$store.getters.getLanguageText('5.15', 27)"></div>
					<div v-else-if="noxType == 'deleteServer'" v-html="$store.getters.getLanguageText('5.15', 7)"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_countdown" v-if="noxIsCountdown">
					<div class="nox_modal_countdown_block">
						<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g><circle cx="50" cy="50" r="45"></circle><path :stroke-dasharray="noxServerErrorCountdownProgress" d="M 50,50 m -45,0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"></path></g></svg>
						<span v-html="noxServerErrorCountdownValue"></span>
					</div>
				</div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="back" v-if="((noxType == 'buyServer' && noxStep > 1) || (noxType == 'extendServerV2' && noxStep > 2))" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="noxStep = 2" v-if="['buyServer', 'extendServerV2'].includes(noxType) && noxStep == 1" v-html="$store.getters.getLanguageText('1.1', 84)"></button>
					<button type="button" class="nox_button common green" @click="noxStep = 3" v-else-if="['buyServer', 'extendServerV2'].includes(noxType) && noxStep == 2 && ((noxServerPeriod == 1 && noxIsBuyM1) || (noxServerPeriod == 2 && noxIsBuyM2) || (noxServerPeriod == 3 && noxIsBuyM3) || (noxServerPeriod == 6 && noxIsBuyM6) || (noxServerPeriod == 12 && noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', (noxType == 'buyServer' ? (noxVersion == 1 ? 85 : 64) : 87))"></button>
					<button type="button" class="nox_button common green" @click="noxStep = 4" v-else-if="noxType == 'buyServer' && noxStep == 3 && noxVersion == 2 && ((noxServerPeriod == 1 && noxIsBuyM1) || (noxServerPeriod == 2 && noxIsBuyM2) || (noxServerPeriod == 3 && noxIsBuyM3) || (noxServerPeriod == 6 && noxIsBuyM6) || (noxServerPeriod == 12 && noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', 85)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['buyServer', 'extendServerV2'].includes(noxType) && noxStep >= 3 && ((noxServerPeriod == 1 && noxIsBuyM1) || (noxServerPeriod == 2 && noxIsBuyM2) || (noxServerPeriod == 3 && noxIsBuyM3) || (noxServerPeriod == 6 && noxIsBuyM6) || (noxServerPeriod == 12 && noxIsBuyM12)) && !noxIsError" v-html="$store.getters.getLanguageText('1.1', (noxType == 'buyServer' ? 66 : 36))"></button>
					<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalBalance', { type: 'addMainBalance' })" v-else-if="['buyServer', 'extendServerV2'].includes(noxType) && noxStep > 1 && ((noxServerPeriod == 1 && !noxIsBuyM1) || (noxServerPeriod == 2 && !noxIsBuyM2) || (noxServerPeriod == 3 && !noxIsBuyM3) || (noxServerPeriod == 6 && !noxIsBuyM6) || (noxServerPeriod == 12 && !noxIsBuyM12))" v-html="$store.getters.getLanguageText('1.1', 100)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editServerComment'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editServerPassword'" v-html="$store.getters.getLanguageText('1.1', 6)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'upgradeServer'" v-html="$store.getters.getLanguageText('1.1', 128)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'refreshServer' && noxStep == 1" v-html="$store.getters.getLanguageText('1.1', 107)"></button>
					<button type="button" class="nox_button common orange" @click="axios" v-else-if="noxType == 'recreateServer'" v-html="$store.getters.getLanguageText('1.1', 139)"></button>
					<button type="button" class="nox_button common orange" @click="axios" v-else-if="noxType == 'reinstallServer'" v-html="$store.getters.getLanguageText('1.1', 139)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'restartServer'" v-html="$store.getters.getLanguageText('1.1', 138)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteServer' && !noxIsError" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
					<button type="button" class="nox_button common dark_red" @click="axios" v-if="noxType == 'deleteServer' && !noxIsError && noxIsDeleteServer" v-html="$store.getters.getLanguageText('1.1', 181)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertServerComment: '',
			noxStep: 0,
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxVersion: 0,
			noxBalance: 0,
			noxBalance1: 0,
			noxBalance2: 0,
			noxBalance3: 0,
			noxSystemVat: 0,
			noxServerCost: 0,
			noxServerPeriod: 0,
			noxServerPeriods: [],
			noxServerPeriodM1: 0,
			noxServerPeriodM2: 0,
			noxServerPeriodM3: 0,
			noxServerPeriodM6: 0,
			noxServerPeriodM12: 0,
			noxServerPeriodM1Cost: 0,
			noxServerPeriodM2Cost: 0,
			noxServerPeriodM3Cost: 0,
			noxServerPeriodM6Cost: 0,
			noxServerPeriodM12Cost: 0,
			noxServerPeriodM1TotalCost: 0,
			noxServerPeriodM2TotalCost: 0,
			noxServerPeriodM3TotalCost: 0,
			noxServerPeriodM6TotalCost: 0,
			noxServerPeriodM12TotalCost: 0,
			noxServerErrorCountdownValue: '',
			noxServerErrorCountdownSeconds: 0,
			noxServerErrorCountdownTimerId: 0,
			noxServerErrorCountdownProgress: '',
			noxServerMethod: 0,
			noxServerMethods: [],
			noxServerComment: '',
			noxIsDeleteServer: false,
			noxIsCountdown: false,
			noxIsLoading: false,
			noxIsError: false,
			noxIsBuyM1: false,
			noxIsBuyM2: false,
			noxIsBuyM3: false,
			noxIsBuyM6: false,
			noxIsBuyM12: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxStep = 1;
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxVersion = event.params.version;
				this.noxBalance = 0;
				this.noxBalance1 = 0;
				this.noxBalance2 = 0;
				this.noxBalance3 = 0;
				this.noxSystemVat = 0;
				this.noxServerCost = 0;
				this.noxServerPeriod = 3;
				this.noxServerPeriods = [1,2,3,6,12];
				this.noxServerPeriodM1 = 0;
				this.noxServerPeriodM2 = 0;
				this.noxServerPeriodM3 = 0;
				this.noxServerPeriodM6 = 0;
				this.noxServerPeriodM12 = 0;
				this.noxServerPeriodM1Cost = 0;
				this.noxServerPeriodM2Cost = 0;
				this.noxServerPeriodM3Cost = 0;
				this.noxServerPeriodM6Cost = 0;
				this.noxServerPeriodM12Cost = 0;
				this.noxServerPeriodM1TotalCost = 0;
				this.noxServerPeriodM2TotalCost = 0;
				this.noxServerPeriodM3TotalCost = 0;
				this.noxServerPeriodM6TotalCost = 0;
				this.noxServerPeriodM12TotalCost = 0;
				this.noxServerErrorCountdownValue = '';
				this.noxServerErrorCountdownSeconds = 0;
				this.noxServerErrorCountdownTimerId = 0;
				this.noxServerErrorCountdownProgress = '';
				this.noxServerMethod = 3;
				this.noxServerMethods = [1,2,3];
				this.noxServerComment = '';
				this.noxIsDeleteServer = false;
				this.noxIsCountdown = false;
				this.noxIsLoading = false;
				this.noxIsError = false;
				this.noxIsBuyM1 = false;
				this.noxIsBuyM2 = false;
				this.noxIsBuyM3 = false;
				this.noxIsBuyM6 = false;
				this.noxIsBuyM12 = false;

				if (['buyServer', 'extendServerV2'].includes(this.noxType)) {

					this.noxStep = this.noxType == 'buyServer' ? 1 : 2;
					this.noxBalance1 = Number(this.$store.state.noxAccountData.balance_nox);
					this.noxBalance2 = Number(this.$store.state.noxAccountData.balance_nox_zp);
					this.noxBalance3 = Number(this.$store.state.noxAccountData.balance_of_bonuses);
					this.noxBalance = Math.round((this.noxBalance1 + this.noxBalance3) * 100) / 100;
					this.noxSystemVat = Number(this.$store.state.noxSystemSettings.vat_value);
					this.noxServerCost = Number(this.$store.state.noxSystemSettings.server_cost);
					this.noxServerPeriodM1 = Number(this.$store.state.noxSystemSettings.server_period_m1);
					this.noxServerPeriodM2 = Number(this.$store.state.noxSystemSettings.server_period_m2);
					this.noxServerPeriodM3 = Number(this.$store.state.noxSystemSettings.server_period_m3);
					this.noxServerPeriodM6 = Number(this.$store.state.noxSystemSettings.server_period_m6);
					this.noxServerPeriodM12 = Number(this.$store.state.noxSystemSettings.server_period_m12);
					this.noxServerPeriodM1Cost = this.noxServerPeriodM1 * this.noxServerCost;
					this.noxServerPeriodM2Cost = this.noxServerPeriodM2 * this.noxServerCost;
					this.noxServerPeriodM3Cost = this.noxServerPeriodM3 * this.noxServerCost;
					this.noxServerPeriodM6Cost = this.noxServerPeriodM6 * this.noxServerCost;
					this.noxServerPeriodM12Cost = this.noxServerPeriodM12 * this.noxServerCost;
					this.noxServerPeriodM1TotalCost = this.noxServerPeriodM1Cost * (1 + this.noxSystemVat / 100);
					this.noxServerPeriodM2TotalCost = this.noxServerPeriodM2Cost * (1 + this.noxSystemVat / 100);
					this.noxServerPeriodM3TotalCost = this.noxServerPeriodM3Cost * (1 + this.noxSystemVat / 100);
					this.noxServerPeriodM6TotalCost = this.noxServerPeriodM6Cost * (1 + this.noxSystemVat / 100);
					this.noxServerPeriodM12TotalCost = this.noxServerPeriodM12Cost * (1 + this.noxSystemVat / 100);
					this.noxServerPeriodM1TotalCost = Math.round(this.noxServerPeriodM1TotalCost * 100) / 100;
					this.noxServerPeriodM2TotalCost = Math.round(this.noxServerPeriodM2TotalCost * 100) / 100;
					this.noxServerPeriodM3TotalCost = Math.round(this.noxServerPeriodM3TotalCost * 100) / 100;
					this.noxServerPeriodM6TotalCost = Math.round(this.noxServerPeriodM6TotalCost * 100) / 100;
					this.noxServerPeriodM12TotalCost = Math.round(this.noxServerPeriodM12TotalCost * 100) / 100;
					this.noxIsBuyM1 = this.noxBalance >= this.noxServerPeriodM1TotalCost ? true : false;
					this.noxIsBuyM2 = this.noxBalance >= this.noxServerPeriodM2TotalCost ? true : false;
					this.noxIsBuyM3 = this.noxBalance >= this.noxServerPeriodM3TotalCost ? true : false;
					this.noxIsBuyM6 = this.noxBalance >= this.noxServerPeriodM6TotalCost ? true : false;
					this.noxIsBuyM12 = this.noxBalance >= this.noxServerPeriodM12TotalCost ? true : false;

					if (this.noxType == 'extendServerV2') {
						if (![1,2,10,40,41,42,90,100,109,522].includes(this.$store.state.noxAccountData.id)) {
							this.noxServerPeriod = this.$parent.noxServersData[this.noxIndex].is_nox_demo ? 1 : this.noxServerPeriod;
							this.noxServerPeriods = this.$parent.noxServersData[this.noxIndex].is_nox_demo ? [1] : this.noxServerPeriods;
						}
					}
				}
				else if (this.noxType == 'editServerComment') {
					this.noxServerComment = this.$parent.noxServersData[this.noxIndex].comment;
				}
			},
			beforeClose: function() {
				if (this.noxServerErrorCountdownTimerId) {
					clearInterval(this.noxServerErrorCountdownTimerId);
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertServerComment = '';
			},
			isServerMethodAvailable: function(method) {

				var isAvailable = 0;
				var availability = String(this.$store.state.noxSystemSettings['server_method_' + method + '_availability']).split(',');
				if (availability.length) {
					if      (availability[0] == 'ALL') { isAvailable = 1; }
					else if (availability.includes(String(this.$store.state.noxAccountData.id))) { isAvailable = 1; }
				}

				return isAvailable;
			},
			initServerErrorCountdown: function(error, seconds, progress) {

				var _this = this;
				_this.noxAlert = _this.getError(error);
				_this.noxServerErrorCountdownSeconds = seconds;
				_this.noxServerErrorCountdownProgress = progress;
				_this.noxIsCountdown = true; _this.getServerErrorCountdown();
				_this.noxServerErrorCountdownTimerId = setInterval(function() {
					_this.noxServerErrorCountdownSeconds--;
					_this.noxServerErrorCountdownProgress = (((_this.noxServerErrorCountdownSeconds - 1) * progress / seconds) + ' ' + progress);
					_this.getServerErrorCountdown();
				}, 1000);
			},
			getServerErrorCountdown: function() {

				var seconds = this.noxServerErrorCountdownSeconds; seconds = (seconds > 0 ? seconds : 0);
				var minutes = parseInt(seconds / 60); seconds = seconds % 60;

				minutes = this.$parent.$parent.$parent.getNumberPad(Math.floor(minutes));
				seconds = this.$parent.$parent.$parent.getNumberPad(Math.floor(seconds));

				this.noxServerErrorCountdownValue = (minutes + ' : ' + seconds);

				if (Number(minutes) <= 0 && Number(seconds) < 1) {
					this.noxAlert = ''; this.noxIsCountdown = false; this.noxIsError = false; clearInterval(this.noxServerErrorCountdownTimerId);
				}
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 8); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 9); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 10); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 11); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 12); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 13); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 14); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 15); }
				else if (i == 17) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 16); }
				else if (i == 18) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 17); }
				else if (i == 19) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 18); }
				else if (i == 20) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 19); }
				else if (i == 21) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 20); }
				else if (i == 22) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 21); }
				else if (i == 23) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 22); }
				else if (i == 24) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 23); }
				else if (i == 25) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 24); }
				else if (i == 26) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 25); }
				else if (i == 27) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 26); }
				else if (i == 28) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 27); }
				else if (i == 29) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 28); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['buyServer', 'extendServerV2'].includes(_this.noxType)) {
						if ((_this.noxServerPeriod == 1 && _this.noxIsBuyM1) || (_this.noxServerPeriod == 2 && _this.noxIsBuyM2) || (_this.noxServerPeriod == 3 && _this.noxIsBuyM3) || (_this.noxServerPeriod == 6 && _this.noxIsBuyM6) || (_this.noxServerPeriod == 12 && _this.noxIsBuyM12)) {
							config.url = '/v2/account/servers/' + (_this.noxType == 'buyServer' ? 'buy' : 'extend');
							config.data = { id: _this.noxId, type: 1, period: _this.noxServerPeriod, method: _this.noxServerMethod, version: _this.noxVersion };
							config.method = _this.noxType == 'buyServer' ? 'post' : 'patch';
						}
						else {
							_this.$parent.$parent.$parent.goToTop(true); _this.$router.push({ path: '/account/balance' }); return false;
						}
					}
					else if (_this.noxType == 'editServerComment') {
						if (_this.noxServerComment && (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxServerComment) || _this.noxServerComment.length > 200)) {
							_this.noxAlertServerComment = _this.getError(6);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/servers/comment';
							config.data = { id: _this.noxId, comment: _this.noxServerComment };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'editServerPassword') {
						config.url = '/v2/account/servers/password';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'upgradeServer') {
						config.url = '/v2/account/servers/upgrade';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'refreshServer') {
						config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/servers/refresh';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'recreateServer') {
						config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/servers/recreate';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'reinstallServer') {
						config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/servers/reinstall';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'restartServer') {
						config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/servers/restart';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'deleteServer') {
						config.url = '/v2' + ([1,2].includes(_this.$store.state.noxAccountData.group) ? '/admin' : '') + '/account/servers';
						config.data = { id: _this.noxId, is_forcibly: Number(_this.noxIsDeleteServer) };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'buyServer') {
									_this.$parent.noxServersActiveCount++;
									_this.$parent.noxServersData.push(data.data.server_data);
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
									_this.$parent.initData();
								}
								else if (_this.noxType == 'extendServerV2') {
									_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data.server_data);
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
								}
								else if (_this.noxType == 'editServerComment') {
									_this.$parent.noxServersData[_this.noxIndex].comment = _this.noxServerComment;
								}
								else if (_this.noxType == 'editServerPassword') {
									_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'upgradeServer') {
									_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'refreshServer') {
									if (data.data.server_data !== undefined) {
										_this.noxStep = (((data.data.server_data.server_ip !== _this.$parent.noxServersData[_this.noxIndex].server_ip) || (data.data.server_data.server_port !== _this.$parent.noxServersData[_this.noxIndex].server_port) || (Number(_this.$parent.noxServersData[_this.noxIndex].version) == 2 && data.data.server_data.server_hostname && data.data.server_data.server_hostname !== _this.$parent.noxServersData[_this.noxIndex].server_hostname)) ? 3 : 2);
										_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data.server_data); return false;
									}
								}
								else if (_this.noxType == 'recreateServer') {
									if (data.data.server_data !== undefined) {
										_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data.server_data);
									}
								}
								else if (_this.noxType == 'reinstallServer') {
									if (data.data.server_data !== undefined) {
										_this.$parent.noxServersData.splice(_this.noxIndex, 1, data.data.server_data);
									}
								}
								else if (_this.noxType == 'deleteServer') {
									_this.$parent.noxServersActiveCount--;
									_this.$parent.noxServersDeletedCount++;
									_this.$parent.noxServersData.splice(_this.noxIndex, 1);
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
									_this.$parent.initData('deleteServer', _this.noxIndex);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlert = _this.getError(27); }
									else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlert = _this.getError(28); }
									else if (data.response.data.error == 'PERIOD_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
									else if (data.response.data.error == 'PERIOD_NOT_VALID') { _this.noxAlert = _this.getError(4); }
									else if (data.response.data.error == 'COMMENT_IS_EMPTY') { _this.noxAlertServerComment = _this.getError(5); }
									else if (data.response.data.error == 'COMMENT_NOT_VALID') { _this.noxAlertServerComment = _this.getError(6); }
									else if (data.response.data.error == 'VERSION_IS_EMPTY') { _this.noxAlert = _this.getError(15); }
									else if (data.response.data.error == 'VERSION_NOT_VALID') { _this.noxAlert = _this.getError(16); }
									else if (data.response.data.error == 'METHOD_IS_EMPTY') { _this.noxAlert = _this.getError(17); }
									else if (data.response.data.error == 'METHOD_NOT_VALID') { _this.noxAlert = _this.getError(18); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(14); }
								}
								else if (data.response.status == 409) {
									if      (data.response.data.error == 'NEGATIVE_BALANCE') { _this.noxAlert = _this.getError(7); }
									else if (data.response.data.error == 'NOT_ENOUGH_MONEY') { _this.noxAlert = _this.getError(8); }
									else if (data.response.data.error == 'NO_SERVERS_TO_BUY') { _this.noxAlert = _this.getError(9); }
									else if (data.response.data.error == 'SERVER_NOT_CREATED') { _this.initServerErrorCountdown(10, 60, 283); }
									else if (data.response.data.error == 'SERVER_NOT_DELETED') { _this.initServerErrorCountdown(11, 60, 283); _this.noxIsDeleteServer = true; }
									else if (data.response.data.error == 'SERVER_NOT_REINSTALLED') { _this.noxAlert = _this.getError(19); }
									else if (data.response.data.error == 'SERVER_NOT_RESTARTED') { _this.noxAlert = _this.getError(20); }
									else if (data.response.data.error == 'METHOD_NOT_AVAILABLE') { _this.noxAlert = _this.getError(21); }
									else if (data.response.data.error == 'NO_SERVER_INFO') { _this.noxAlert = _this.getError(12); }
									else if (data.response.data.error == 'NO_SERVER_PASSWORD') { _this.noxAlert = _this.getError(13); }
									else if (data.response.data.error == 'PURCHASE_UNAVAILABLE') { _this.noxAlert = _this.getError(24); }
									else if (data.response.data.error == 'CANNOT_BE_EXTENDED') { _this.noxAlert = _this.getError(25); }
									else if (data.response.data.error == 'CANNOT_BE_AUTO_EXTENDED') { _this.noxAlert = _this.getError(26); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			back: function() {
				if (!this.noxIsCountdown) {
					this.resetAlerts();
					this.noxStep--;
				}
			},
			close: function() {
				this.$modal.hide('NoxModalServers');
			}
		}
	}
</script>
